exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-identitet-index-mdx": () => import("./../../../src/pages/identitet/index.mdx" /* webpackChunkName: "component---src-pages-identitet-index-mdx" */),
  "component---src-pages-identitet-introduksjon-merkevareplattform-mdx": () => import("./../../../src/pages/identitet/introduksjon/merkevareplattform.mdx" /* webpackChunkName: "component---src-pages-identitet-introduksjon-merkevareplattform-mdx" */),
  "component---src-pages-identitet-introduksjon-toneofvoice-mdx": () => import("./../../../src/pages/identitet/introduksjon/toneofvoice.mdx" /* webpackChunkName: "component---src-pages-identitet-introduksjon-toneofvoice-mdx" */),
  "component---src-pages-identitet-introduksjon-verdier-mdx": () => import("./../../../src/pages/identitet/introduksjon/verdier.mdx" /* webpackChunkName: "component---src-pages-identitet-introduksjon-verdier-mdx" */),
  "component---src-pages-identitet-maler-epost-mdx": () => import("./../../../src/pages/identitet/maler/epost.mdx" /* webpackChunkName: "component---src-pages-identitet-maler-epost-mdx" */),
  "component---src-pages-identitet-maler-powerpoint-mdx": () => import("./../../../src/pages/identitet/maler/powerpoint.mdx" /* webpackChunkName: "component---src-pages-identitet-maler-powerpoint-mdx" */),
  "component---src-pages-identitet-maler-skjermbakgrunn-mdx": () => import("./../../../src/pages/identitet/maler/skjermbakgrunn.mdx" /* webpackChunkName: "component---src-pages-identitet-maler-skjermbakgrunn-mdx" */),
  "component---src-pages-identitet-maler-visittkort-mdx": () => import("./../../../src/pages/identitet/maler/visittkort.mdx" /* webpackChunkName: "component---src-pages-identitet-maler-visittkort-mdx" */),
  "component---src-pages-identitet-maler-word-maler-mdx": () => import("./../../../src/pages/identitet/maler/word-maler.mdx" /* webpackChunkName: "component---src-pages-identitet-maler-word-maler-mdx" */),
  "component---src-pages-identitet-verktoykassen-animasjoner-mdx": () => import("./../../../src/pages/identitet/verktoykassen/animasjoner.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-animasjoner-mdx" */),
  "component---src-pages-identitet-verktoykassen-co-branding-mdx": () => import("./../../../src/pages/identitet/verktoykassen/co-branding.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-co-branding-mdx" */),
  "component---src-pages-identitet-verktoykassen-datavisualisering-mdx": () => import("./../../../src/pages/identitet/verktoykassen/datavisualisering.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-datavisualisering-mdx" */),
  "component---src-pages-identitet-verktoykassen-entur-mdx": () => import("./../../../src/pages/identitet/verktoykassen/entur.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-entur-mdx" */),
  "component---src-pages-identitet-verktoykassen-farger-mdx": () => import("./../../../src/pages/identitet/verktoykassen/farger.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-farger-mdx" */),
  "component---src-pages-identitet-verktoykassen-foto-mdx": () => import("./../../../src/pages/identitet/verktoykassen/foto.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-foto-mdx" */),
  "component---src-pages-identitet-verktoykassen-grafiskeelementer-mdx": () => import("./../../../src/pages/identitet/verktoykassen/grafiskeelementer.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-grafiskeelementer-mdx" */),
  "component---src-pages-identitet-verktoykassen-ikoner-mdx": () => import("./../../../src/pages/identitet/verktoykassen/ikoner.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-ikoner-mdx" */),
  "component---src-pages-identitet-verktoykassen-illustrasjoner-mdx": () => import("./../../../src/pages/identitet/verktoykassen/illustrasjoner.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-illustrasjoner-mdx" */),
  "component---src-pages-identitet-verktoykassen-logo-mdx": () => import("./../../../src/pages/identitet/verktoykassen/logo.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-logo-mdx" */),
  "component---src-pages-identitet-verktoykassen-typografi-mdx": () => import("./../../../src/pages/identitet/verktoykassen/typografi.mdx" /* webpackChunkName: "component---src-pages-identitet-verktoykassen-typografi-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kom-i-gang-for-designere-brukergrupper-mdx": () => import("./../../../src/pages/kom-i-gang/for-designere/brukergrupper.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-for-designere-brukergrupper-mdx" */),
  "component---src-pages-kom-i-gang-for-designere-brukerhistorier-mdx": () => import("./../../../src/pages/kom-i-gang/for-designere/brukerhistorier.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-for-designere-brukerhistorier-mdx" */),
  "component---src-pages-kom-i-gang-for-designere-brukertesting-mdx": () => import("./../../../src/pages/kom-i-gang/for-designere/brukertesting.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-for-designere-brukertesting-mdx" */),
  "component---src-pages-kom-i-gang-for-designere-designprosess-mdx": () => import("./../../../src/pages/kom-i-gang/for-designere/designprosess.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-for-designere-designprosess-mdx" */),
  "component---src-pages-kom-i-gang-for-utviklere-bidra-med-kode-mdx": () => import("./../../../src/pages/kom-i-gang/for-utviklere/bidra-med-kode.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-for-utviklere-bidra-med-kode-mdx" */),
  "component---src-pages-kom-i-gang-for-utviklere-komponentbibliotek-mdx": () => import("./../../../src/pages/kom-i-gang/for-utviklere/komponentbibliotek.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-for-utviklere-komponentbibliotek-mdx" */),
  "component---src-pages-kom-i-gang-index-mdx": () => import("./../../../src/pages/kom-i-gang/index.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-index-mdx" */),
  "component---src-pages-kom-i-gang-introduksjon-arbeidskultur-mdx": () => import("./../../../src/pages/kom-i-gang/introduksjon/arbeidskultur.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-introduksjon-arbeidskultur-mdx" */),
  "component---src-pages-kom-i-gang-introduksjon-designprinsipper-mdx": () => import("./../../../src/pages/kom-i-gang/introduksjon/designprinsipper.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-introduksjon-designprinsipper-mdx" */),
  "component---src-pages-kom-i-gang-introduksjon-hva-vi-gjor-mdx": () => import("./../../../src/pages/kom-i-gang/introduksjon/hva-vi-gjor.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-introduksjon-hva-vi-gjor-mdx" */),
  "component---src-pages-kom-i-gang-introduksjon-om-entur-mdx": () => import("./../../../src/pages/kom-i-gang/introduksjon/om-entur.mdx" /* webpackChunkName: "component---src-pages-kom-i-gang-introduksjon-om-entur-mdx" */),
  "component---src-pages-komponenter-feedback-alert-mdx": () => import("./../../../src/pages/komponenter/feedback/alert.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-alert-mdx" */),
  "component---src-pages-komponenter-feedback-badge-mdx": () => import("./../../../src/pages/komponenter/feedback/badge.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-badge-mdx" */),
  "component---src-pages-komponenter-feedback-copyable-text-mdx": () => import("./../../../src/pages/komponenter/feedback/copyable-text.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-copyable-text-mdx" */),
  "component---src-pages-komponenter-feedback-loader-mdx": () => import("./../../../src/pages/komponenter/feedback/loader.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-loader-mdx" */),
  "component---src-pages-komponenter-feedback-popover-mdx": () => import("./../../../src/pages/komponenter/feedback/popover.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-popover-mdx" */),
  "component---src-pages-komponenter-feedback-skeleton-mdx": () => import("./../../../src/pages/komponenter/feedback/skeleton.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-skeleton-mdx" */),
  "component---src-pages-komponenter-feedback-tooltip-mdx": () => import("./../../../src/pages/komponenter/feedback/tooltip.mdx" /* webpackChunkName: "component---src-pages-komponenter-feedback-tooltip-mdx" */),
  "component---src-pages-komponenter-index-mdx": () => import("./../../../src/pages/komponenter/index.mdx" /* webpackChunkName: "component---src-pages-komponenter-index-mdx" */),
  "component---src-pages-komponenter-knapper-button-mdx": () => import("./../../../src/pages/komponenter/knapper/button.mdx" /* webpackChunkName: "component---src-pages-komponenter-knapper-button-mdx" */),
  "component---src-pages-komponenter-knapper-chip-mdx": () => import("./../../../src/pages/komponenter/knapper/chip.mdx" /* webpackChunkName: "component---src-pages-komponenter-knapper-chip-mdx" */),
  "component---src-pages-komponenter-knapper-floating-button-mdx": () => import("./../../../src/pages/komponenter/knapper/floating-button.mdx" /* webpackChunkName: "component---src-pages-komponenter-knapper-floating-button-mdx" */),
  "component---src-pages-komponenter-knapper-icon-button-mdx": () => import("./../../../src/pages/komponenter/knapper/icon-button.mdx" /* webpackChunkName: "component---src-pages-komponenter-knapper-icon-button-mdx" */),
  "component---src-pages-komponenter-knapper-square-button-mdx": () => import("./../../../src/pages/komponenter/knapper/square-button.mdx" /* webpackChunkName: "component---src-pages-komponenter-knapper-square-button-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-card-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/card.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-card-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-check-icon-tsx": () => import("./../../../src/pages/komponenter/layout-og-flater/CheckIcon.tsx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-check-icon-tsx" */),
  "component---src-pages-komponenter-layout-og-flater-contrast-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/contrast.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-contrast-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-drawer-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/drawer.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-drawer-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-expandable-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/expandable.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-expandable-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-mediacard-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/mediacard.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-mediacard-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-modal-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/modal.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-modal-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-tab-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/tab.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-tab-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-table-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/table.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-table-mdx" */),
  "component---src-pages-komponenter-layout-og-flater-tag-mdx": () => import("./../../../src/pages/komponenter/layout-og-flater/tag.mdx" /* webpackChunkName: "component---src-pages-komponenter-layout-og-flater-tag-mdx" */),
  "component---src-pages-komponenter-navigasjon-breadcrumbs-mdx": () => import("./../../../src/pages/komponenter/navigasjon/breadcrumbs.mdx" /* webpackChunkName: "component---src-pages-komponenter-navigasjon-breadcrumbs-mdx" */),
  "component---src-pages-komponenter-navigasjon-overflow-menu-mdx": () => import("./../../../src/pages/komponenter/navigasjon/overflow-menu.mdx" /* webpackChunkName: "component---src-pages-komponenter-navigasjon-overflow-menu-mdx" */),
  "component---src-pages-komponenter-navigasjon-pagination-mdx": () => import("./../../../src/pages/komponenter/navigasjon/pagination.mdx" /* webpackChunkName: "component---src-pages-komponenter-navigasjon-pagination-mdx" */),
  "component---src-pages-komponenter-navigasjon-side-navigation-mdx": () => import("./../../../src/pages/komponenter/navigasjon/side-navigation.mdx" /* webpackChunkName: "component---src-pages-komponenter-navigasjon-side-navigation-mdx" */),
  "component---src-pages-komponenter-navigasjon-stepper-mdx": () => import("./../../../src/pages/komponenter/navigasjon/stepper.mdx" /* webpackChunkName: "component---src-pages-komponenter-navigasjon-stepper-mdx" */),
  "component---src-pages-komponenter-navigasjon-top-navigation-mdx": () => import("./../../../src/pages/komponenter/navigasjon/top-navigation.mdx" /* webpackChunkName: "component---src-pages-komponenter-navigasjon-top-navigation-mdx" */),
  "component---src-pages-komponenter-reise-travel-leg-mdx": () => import("./../../../src/pages/komponenter/reise/travel-leg.mdx" /* webpackChunkName: "component---src-pages-komponenter-reise-travel-leg-mdx" */),
  "component---src-pages-komponenter-reise-travelheader-mdx": () => import("./../../../src/pages/komponenter/reise/travelheader.mdx" /* webpackChunkName: "component---src-pages-komponenter-reise-travelheader-mdx" */),
  "component---src-pages-komponenter-reise-travelswitch-mdx": () => import("./../../../src/pages/komponenter/reise/travelswitch.mdx" /* webpackChunkName: "component---src-pages-komponenter-reise-travelswitch-mdx" */),
  "component---src-pages-komponenter-reise-traveltag-mdx": () => import("./../../../src/pages/komponenter/reise/traveltag.mdx" /* webpackChunkName: "component---src-pages-komponenter-reise-traveltag-mdx" */),
  "component---src-pages-komponenter-ressurser-changelog-mdx": () => import("./../../../src/pages/komponenter/ressurser/changelog.mdx" /* webpackChunkName: "component---src-pages-komponenter-ressurser-changelog-mdx" */),
  "component---src-pages-komponenter-ressurser-grid-mdx": () => import("./../../../src/pages/komponenter/ressurser/grid.mdx" /* webpackChunkName: "component---src-pages-komponenter-ressurser-grid-mdx" */),
  "component---src-pages-komponenter-ressurser-icons-mdx": () => import("./../../../src/pages/komponenter/ressurser/icons.mdx" /* webpackChunkName: "component---src-pages-komponenter-ressurser-icons-mdx" */),
  "component---src-pages-komponenter-ressurser-tilgjengelighet-mdx": () => import("./../../../src/pages/komponenter/ressurser/tilgjengelighet.mdx" /* webpackChunkName: "component---src-pages-komponenter-ressurser-tilgjengelighet-mdx" */),
  "component---src-pages-komponenter-ressurser-typography-mdx": () => import("./../../../src/pages/komponenter/ressurser/typography.mdx" /* webpackChunkName: "component---src-pages-komponenter-ressurser-typography-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-checkbox-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/checkbox.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-checkbox-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-checkbox-panel-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/checkbox-panel.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-checkbox-panel-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-datepicker-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/datepicker.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-datepicker-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-dropdown-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/dropdown.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-dropdown-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-fileupload-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/fileupload.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-fileupload-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-multiselect-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/multiselect.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-multiselect-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-radio-button-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/radio-button.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-radio-button-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-radio-panel-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/radio-panel.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-radio-panel-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-segmentedcontrol-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/segmentedcontrol.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-segmentedcontrol-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-switch-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/switch.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-switch-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-textarea-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/textarea.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-textarea-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-textfield-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/textfield.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-textfield-mdx" */),
  "component---src-pages-komponenter-skjemaelementer-timepicker-mdx": () => import("./../../../src/pages/komponenter/skjemaelementer/timepicker.mdx" /* webpackChunkName: "component---src-pages-komponenter-skjemaelementer-timepicker-mdx" */),
  "component---src-pages-tokens-fargetokens-darkmode-mdx": () => import("./../../../src/pages/tokens/fargetokens/darkmode.mdx" /* webpackChunkName: "component---src-pages-tokens-fargetokens-darkmode-mdx" */),
  "component---src-pages-tokens-fargetokens-generelt-mdx": () => import("./../../../src/pages/tokens/fargetokens/generelt.mdx" /* webpackChunkName: "component---src-pages-tokens-fargetokens-generelt-mdx" */),
  "component---src-pages-tokens-fargetokens-oversikt-farger-mdx": () => import("./../../../src/pages/tokens/fargetokens/oversikt-farger.mdx" /* webpackChunkName: "component---src-pages-tokens-fargetokens-oversikt-farger-mdx" */),
  "component---src-pages-tokens-index-mdx": () => import("./../../../src/pages/tokens/index.mdx" /* webpackChunkName: "component---src-pages-tokens-index-mdx" */),
  "component---src-pages-tokens-ovrigetokens-border-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/border.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-border-mdx" */),
  "component---src-pages-tokens-ovrigetokens-breakpoints-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/breakpoints.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-breakpoints-mdx" */),
  "component---src-pages-tokens-ovrigetokens-pxrem-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/pxrem.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-pxrem-mdx" */),
  "component---src-pages-tokens-ovrigetokens-shadows-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/shadows.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-shadows-mdx" */),
  "component---src-pages-tokens-ovrigetokens-spacing-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/spacing.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-spacing-mdx" */),
  "component---src-pages-tokens-ovrigetokens-timings-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/timings.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-timings-mdx" */),
  "component---src-pages-tokens-ovrigetokens-typografi-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/typografi.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-typografi-mdx" */),
  "component---src-pages-tokens-ovrigetokens-zindex-mdx": () => import("./../../../src/pages/tokens/ovrigetokens/zindex.mdx" /* webpackChunkName: "component---src-pages-tokens-ovrigetokens-zindex-mdx" */),
  "component---src-pages-universell-utforming-hvem-designer-mdx": () => import("./../../../src/pages/universell-utforming/hvem-designer.mdx" /* webpackChunkName: "component---src-pages-universell-utforming-hvem-designer-mdx" */),
  "component---src-pages-universell-utforming-index-mdx": () => import("./../../../src/pages/universell-utforming/index.mdx" /* webpackChunkName: "component---src-pages-universell-utforming-index-mdx" */),
  "component---src-pages-universell-utforming-kontrast-sjekker-mdx": () => import("./../../../src/pages/universell-utforming/kontrast-sjekker.mdx" /* webpackChunkName: "component---src-pages-universell-utforming-kontrast-sjekker-mdx" */),
  "component---src-pages-universell-utforming-nyttige-verktoy-mdx": () => import("./../../../src/pages/universell-utforming/nyttige-verktoy.mdx" /* webpackChunkName: "component---src-pages-universell-utforming-nyttige-verktoy-mdx" */),
  "component---src-pages-universell-utforming-testguide-mdx": () => import("./../../../src/pages/universell-utforming/testguide.mdx" /* webpackChunkName: "component---src-pages-universell-utforming-testguide-mdx" */)
}

